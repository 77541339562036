/**
 * Keep track of all Kameleoon experiments.
 * Remember to remove related code when the experiment is done.
 */
export enum ActiveExperiments {
  MobileTimetableOnlyUK = 'MOBILE_TIMETABLE_ONLY_UK',
  MobileTimetableOnlyFR = 'MOBILE_TIMETABLE_ONLY_FR',

  DiscountBannerPCT = 'DISCOUNT_BANNER_PCT_V2',

  EWOShockPricingUK = 'EWO_SHOCK_PRICING_UK',
  EWOShockPricingFR = 'EWO_SHOCK_PRICING_FR',

  ServicingQuotesPriceMatchPromiseUK = 'SERVICING_QUOTES_PRICE_MATCH_PROMISE_UK',

  KlarnaFakeDoorInCheckoutFlowUK = 'KLARNA_FAKE_DOOR_IN_CHECKOUT_FLOW_UK',

  BrandCampaignUK = 'BRAND_CAMPAIGN_UK',

  /**
   * Active campaign popup form.
   */
  ACForm10Off = 'AC_FORM_10_OFF',

  RevisionConstructeurFR = 'REVISION_CONSTRUCTEUR_FR',
  HomepageCtaServicesOverview = 'HOMEPAGE_CTA_SERVICES_OVERVIEW',

  PaymentSummaryUK = 'PAYMENT_SUMMARY_UK',
  PaymentSummaryFR = 'PAYMENT_SUMMARY_FR',

  CndPriceFR = 'CND_PRICE_FR',

  CustomerCndMapViewUK = 'CUSTOMER_CND_MAP_VIEW_UK',
}
